import React from 'react';
export const Nav00DataSource = {
  wrapper: { className: 'header0 home-page-wrapper llw39scvxp-editor_css' },
  page: { className: 'home-page llw2nptuj1n-editor_css' },
  logo: {
    className: 'header0-logo llw2bfl724-editor_css',
    children:
      'https://pgp1024daren.oss-cn-shanghai.aliyuncs.com/static/image/1yf_slices/logo.png',
  },
  Menu: {
    className: 'header0-menu',
    children: [
      {
        name: 'item0',
        className: 'header0-item',
        children: {
          href: '#root',
          children: [
            {
              children: (
                <span>
                  <p>
                    <span>首页</span>
                    <br />
                  </p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item1',
        className: 'header0-item',
        children: {
          href: '#functions',
          children: [
            {
              children: (
                <span>
                  <p>
                    <span>核心功能</span>
                    <br />
                  </p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item2',
        className: 'header0-item',
        children: {
          href: '#Teams10',
          children: [
            {
              children: (
                <span>
                   <p>
                    <span>合作商</span>
                    <br />
                  </p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
       
      },
      {
        name: 'item3',
        className: 'header0-item',
        children: {
          href: '#Teams11',
          children: [
            {
              children: (
                <span>
                   <p>
                    <span>证书</span>
                    <br />
                  </p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
    ],
  },
  mobileMenu: { className: 'header0-mobile-menu' },
};
export const Banner00DataSource = {
  wrapper: { className: 'banner0 llvxzjq9ydh-editor_css' },
  textWrapper: { className: 'banner0-text-wrapper llw3n4533k-editor_css' },
  title: {
    className: 'banner0-title ll4zvl3rn-editor_css',
    children:
      'https://pgp1024daren.oss-cn-shanghai.aliyuncs.com/static/image/yinfu/%E7%BB%84%202%402x.png',
  },
  content: {
    className: 'banner0-content',
    children: (
      <span>
        <span>
          <p>
            一个可以转发抖音视频的收款码<br />
          </p>
        </span>
      </span>
    ),
  },
  button: {
    className: 'banner0-button',
    children: (
      <span>
        <span>
          <p>
            了解<span>更多</span>
          </p>
        </span>
      </span>
    ),
  },
};
export const Feature00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0 llw3qwb98xh-editor_css' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>
              <br />
            </p>
          </span>
        ),
        className: 'llw3qx2w9xj-editor_css',
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://pgp1024daren.oss-cn-shanghai.aliyuncs.com/static/image/1yf_slices/nav3.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>
                    <span>原创</span>
                    <br />
                  </p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <h2>
                      <b>1.5954.2151+</b>
                    </h2>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://pgp1024daren.oss-cn-shanghai.aliyuncs.com/static/image/1yf_slices/nav2.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>商户</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <h2>
                      <b>954.2151+</b>
                    </h2>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://pgp1024daren.oss-cn-shanghai.aliyuncs.com/static/image/1yf_slices/nav1.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>
                    <span>交易额</span>
                    <br />
                  </p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <h2>
                      <b>56.8954.2151+</b>
                    </h2>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Feature10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: 'https://pgp1024daren.oss-cn-shanghai.aliyuncs.com/static/image/1yf_slices/mobile5.png',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>他们都在用音付</p>
          </span>
        ),
      },
    ],
  },
  title: {
    className: 'content1-title',
    children: (
      <span>
        <p>一键转发</p>
      </span>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <span>
        <span>
          <p>
            支持多平台短视频与直播一键挂载内容讲解卡，营造场景氛围感，带动用户情绪，从而促进用户下单。支持多平台短视频与直播一键挂载内容讲解卡，营造场景氛围感，带动用户情绪，从而促进用户下单。支持多平台短视频与直播一键挂载内容讲解卡，营造场景氛围感，带动用户情绪，从而促进用户下单。
          </p>
        </span>
      </span>
    ),
  },
};
export const Feature20DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: {
    children: 'https://pgp1024daren.oss-cn-shanghai.aliyuncs.com/static/image/1yf_slices/mobile4.png',
  },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: {
    className: 'content2-title',
    children: (
      <span>
        <p>视频制作</p>
      </span>
    ),
  },
  content: {
    className: 'content2-content',
    children: (
      <span>
        <p>
          支持多平台短视频与直播一键挂载内容讲解卡，营造场景氛围感，带动用户情绪，从而促进用户下单。支持多平台短视频与直播一键挂载内容讲解卡，营造场景氛围感，带动用户情绪，从而促进用户下单。支持多平台短视频与直播一键挂载内容讲解卡，营造场景氛围感，带动用户情绪，从而促进用户下单。
        </p>
      </span>
    ),
  },
};
export const Feature11DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: 'https://pgp1024daren.oss-cn-shanghai.aliyuncs.com/static/image/1yf_slices/mobile2.png',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <span>
        <p>聚合收款</p>
      </span>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <span>
        <p>
          支持多平台短视频与直播一键挂载内容讲解卡，营造场景氛围感，带动用户情绪，从而促进用户下单。支持多平台短视频与直播一键挂载内容讲解卡，营造场景氛围感，带动用户情绪，从而促进用户下单。支持多平台短视频与直播一键挂载内容讲解卡，营造场景氛围感，带动用户情绪，从而促进用户下单。
        </p>
      </span>
    ),
  },
};
export const Feature21DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: {
    children: 'https://pgp1024daren.oss-cn-shanghai.aliyuncs.com/static/image/1yf_slices/mobile3.png',
  },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: {
    className: 'content2-title',
    children: (
      <span>
        <p>软硬件结合</p>
      </span>
    ),
  },
  content: {
    className: 'content2-content',
    children: (
      <span>
        <p>
          支持多平台短视频与直播一键挂载内容讲解卡，营造场景氛围感，带动用户情绪，从而促进用户下单。支持多平台短视频与直播一键挂载内容讲解卡，营造场景氛围感，带动用户情绪，从而促进用户下单。支持多平台短视频与直播一键挂载内容讲解卡，营造场景氛围感，带动用户情绪，从而促进用户下单。
        </p>
      </span>
    ),
  },
};
export const Feature12DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: 'https://pgp1024daren.oss-cn-shanghai.aliyuncs.com/static/image/1yf_slices/mobile1.png',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <span>
        <p>层级抽拥</p>
      </span>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <span>
        <p>
          支持多平台短视频与直播一键挂载内容讲解卡，营造场景氛围感，带动用户情绪，从而促进用户下单。支持多平台短视频与直播一键挂载内容讲解卡，营造场景氛围感，带动用户情绪，从而促进用户下单。支持多平台短视频与直播一键挂载内容讲解卡，营造场景氛围感，带动用户情绪，从而促进用户下单。
        </p>
      </span>
    ),
  },
};

export const Teams10DataSource = {
  wrapper: { className: 'home-page-wrapper teams1-wrapper' },
  id:'Teams10',
  page: { className: 'home-page teams1' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>他们都在用音付</p>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams2-image',
              children:
                'https://p26.douyinpic.com/aweme/100x100/aweme-avatar/tos-cn-i-0813_f79c5c89c5834194822b2f20564b9484.jpeg?from=4010531038',
            },
            {
              name: 'title',
              className: 'teams1-title',
              children: (
                <span>
                  <p>阿尔斯兰图书馆</p>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children: (
                <span>
                  <p>
                    感谢一年多时间来的支持，感谢每一位客服工作人员的支持，合作..
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams2-image',
              children:
                'https://p6.douyinpic.com/aweme/100x100/aweme-avatar/tos-cn-avt-0015_180b79e2800179dda79c5af2f8a74a31.jpeg',
            },
            { name: 'title', className: 'teams1-title', children: '韩勇' },
            {
              name: 'content1',
              className: 'teams1-content',
              children:
                '语雀是一款优雅高效的在线文档编辑与协同工具， 让每个企业轻松拥有文档中心阿里巴巴集团内部使用多年，众多中小企业首选。',
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams2-image',
              children:
                'https://p11.douyinpic.com/aweme/100x100/aweme-avatar/tos-cn-avt-0015_aab013d667d183ea787e01f1bebfcad2.jpeg?from=4010531038',
            },
            {
              name: 'title',
              className: 'teams1-title',
              children: (
                <span>
                  <p>房产培训</p>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children:
                'AntV 是蚂蚁金服全新一代数据可视化解决方案，致力于提供一套简单方便、专业可靠、无限可能的数据可视化最佳实践。',
            },
          ],
        },
      },
      {
        name: 'block~llw2focgpre',
        className: 'block',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams2-image',
              children:
                'https://p3.douyinpic.com/aweme/100x100/aweme-avatar/tos-cn-i-c9aec8xkvj_def73d9833314dc0a7b968c039c1331c.jpeg?from=4010531038',
            },
            {
              name: 'title',
              className: 'teams1-title',
              children: (
                <span>
                  <p>疯狂写字</p>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children:
                'AntV 是蚂蚁金服全新一代数据可视化解决方案，致力于提供一套简单方便、专业可靠、无限可能的数据可视化最佳实践。',
            },
          ],
        },
      },
      {
        name: 'block~llw2fpbduol',
        className: 'block',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams2-image',
              children:
                'https://p11.douyinpic.com/aweme/100x100/aweme-avatar/tos-cn-avt-0015_03c324fb28366e05a2ba562734d2a001.jpeg?from=4010531038',
            },
            {
              name: 'title',
              className: 'teams1-title',
              children: (
                <span>
                  <p>劫色可达鸭</p>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children:
                'AntV 是蚂蚁金服全新一代数据可视化解决方案，致力于提供一套简单方便、专业可靠、无限可能的数据可视化最佳实践。',
            },
          ],
        },
      },
      {
        name: 'block~llw2fqtshb',
        className: 'block',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams2-image',
              children:
                'https://p26.douyinpic.com/aweme/100x100/aweme-avatar/tos-cn-i-0813_oAAqAbiunBFJeAKBsHD398IeREEQAk9Aax7jfA.jpeg?from=4010531038',
            },
            {
              name: 'title',
              className: 'teams1-title',
              children: (
                <span>
                  <p>伟娟舞蹈</p>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children:
                'AntV 是蚂蚁金服全新一代数据可视化解决方案，致力于提供一套简单方便、专业可靠、无限可能的数据可视化最佳实践。',
            },
          ],
        },
      },
    ],
  },
};
export const Teams11DataSource = {
  wrapper: { className: 'home-page-wrapper teams1-wrapper' },
  id:'Teams11',
  page: { className: 'home-page teams1' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <p>证书展示</p>
            </span>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 6,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children:
                'https://pgp1024daren.oss-cn-shanghai.aliyuncs.com/static/image/yinfu/%E5%9B%BE%E5%B1%82%2017%402x.png',
            },
            {
              name: 'title',
              className: 'teams1-title',
              children: (
                <span>
                  <p>证书</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 6,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children:
                'https://pgp1024daren.oss-cn-shanghai.aliyuncs.com/static/image/yinfu/%E5%9B%BE%E5%B1%82%2017%402x.png',
            },
            {
              name: 'title',
              className: 'teams1-title',
              children: (
                <span>
                  <p>证书</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 6,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children:
                'https://pgp1024daren.oss-cn-shanghai.aliyuncs.com/static/image/yinfu/%E5%9B%BE%E5%B1%82%2017%402x.png',
            },
            {
              name: 'title',
              className: 'teams1-title',
              children: (
                <span>
                  <p>证书</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block3',
        className: 'block',
        md: 6,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children:
                'https://pgp1024daren.oss-cn-shanghai.aliyuncs.com/static/image/yinfu/%E5%9B%BE%E5%B1%82%2017%402x.png',
            },
            {
              name: 'title',
              className: 'teams1-title',
              children: (
                <span>
                  <p>证书</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block4',
        className: 'block',
        md: 6,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children:
                'https://pgp1024daren.oss-cn-shanghai.aliyuncs.com/static/image/yinfu/%E5%9B%BE%E5%B1%82%2017%402x.png',
            },
            {
              name: 'title',
              className: 'teams1-title',
              children: (
                <span>
                  <p>证书</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block5',
        className: 'block',
        md: 6,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children:
                'https://pgp1024daren.oss-cn-shanghai.aliyuncs.com/static/image/yinfu/%E5%9B%BE%E5%B1%82%2017%402x.png',
            },
            {
              name: 'title',
              className: 'teams1-title',
              children: (
                <span>
                  <p>证书</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block6',
        className: 'block',
        md: 6,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children:
                'https://pgp1024daren.oss-cn-shanghai.aliyuncs.com/static/image/yinfu/%E5%9B%BE%E5%B1%82%2017%402x.png',
            },
            {
              name: 'title',
              className: 'teams1-title',
              children: (
                <span>
                  <p>证书</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block7',
        className: 'block',
        md: 6,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children:
                'https://pgp1024daren.oss-cn-shanghai.aliyuncs.com/static/image/yinfu/%E5%9B%BE%E5%B1%82%2017%402x.png',
            },
            {
              name: 'title',
              className: 'teams1-title',
              children: (
                <span>
                  <p>证书</p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          children: (
            <span>
              <span>
                <p>
                  <br />
                </p>
              </span>
            </span>
          ),
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <p>
                    <img className='bt-logo' src="https://pgp1024daren.oss-cn-shanghai.aliyuncs.com/ice/4/202308/rc-upload-1691573833379-10.png" alt='' />
                  </p>
                </span>
              ),
              className: 'll50v0460od-editor_css',
            },
            {
              name: 'content0',
              children: (
                <span>
                  <p className='cp-name'>
                    广东程序园科技有限公司<br />
                  </p>
                </span>
              ),
              className: 'll50v0460od-editor_css',
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          children: (
            <span>
              <span>
                <p>
                  <br />
                </p>
              </span>
            </span>
          ),
        },
        childWrapper: {
          children: [
           
            {
              name: 'link2',
              // eslint-disable-next-line
              href: 'javaScript:;',
              children: (
                <span>
                  <p>产品经理：156 2271 0815</p>
                </span>
              ),
            },
            {
              name: 'link2',
              // eslint-disable-next-line
              href: 'javaScript:;',
              children: (
                <span>
                  <p>技术售后：156 2610 8616</p>
                </span>
              ),
            },
            {
              name: 'link2',
              // eslint-disable-next-line
              href: 'javaScript:;',
              children: (
                <span>
                  <p>企业邮箱：583141207@qq.com</p>
                </span>
              ),
            },
            {
              name: 'link3',
              href: 'https://beian.miit.gov.cn/',
              children: (
                <span>
                  <p>ICP证：粤ICP备2021100764号 </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          children: (
            <span>
              <span>
                <p>
                  <br />
                </p>
              </span>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              // eslint-disable-next-line
              href: 'javaScript:;',
              name: 'link0',
              children: (
                <span>
                  <span>
                    <p>
                      企业微信：<span>583141207</span>
                    </p>
                  </span>
                </span>
              ),
            },
            {
              // eslint-disable-next-line
              href: 'javaScript:;',
              name: 'link0',
              children: (
                <span>
                  <span>
                    <p>
                      企业电话：<span>0758-7771024</span>
                    </p>
                  </span>
                </span>
              ),
            },
            {
              // eslint-disable-next-line
              href: 'javaScript:;',
              name: 'link1',
              children: (
                <span>
                  <p>企业地址：广东省肇庆市德庆县康城大道时代广场18号商铺</p>
                </span>
              ),
            },
            {
              name: 'link2',
              href: 'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44122602000128',
              children: (
                <span>
                  <p><img  src="https://www.pgp1024.com/PGPSystem/resources/imgs/gongan.png" alt='' />粤公网安备44122602000128号</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          children: (
            <span>
              <span>
                <p>
                  <br />
                </p>
              </span>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              name: 'link2',
              href: 'https://play.pgp1024.com/',
              children: (
                <span>
                  <p><img className='bt-img'  src="https://pgp1024daren.oss-cn-shanghai.aliyuncs.com/static/image/yinfu/YF-04.png" alt='' />音付 </p>
                </span>
              ),
            },
            {
              name: 'link2',
              // eslint-disable-next-line
              href: 'javaScript:;',
              children: (
                <span>
                  <p><img className='bt-img'  src="https://pgp1024daren.oss-cn-shanghai.aliyuncs.com/ice/4/202307/rc-upload-1690362073685-9.png" alt='' />矩阵熊</p>
                </span>
              ),
            },
            {
              name: 'link2',
              // eslint-disable-next-line
              href: 'javaScript:;',
              children: (
                <span>
                  <p><img className='bt-img' src="https://pgp1024daren.oss-cn-shanghai.aliyuncs.com/static/image/lineLeopard-bg.png" alt='' />直播豹</p>
                </span>
              ),
            },
            {
              name: 'link2',
              // eslint-disable-next-line
              href: 'javaScript:;',
              children: (
                <span>
                  <p><img className='bt-img'  src="https://pgp1024douyinnew.oss-cn-shanghai.aliyuncs.com/5671682059770-pic.svg" alt='' />助力小店</p>
                </span>
              ),
            },
            {
              name: 'link2',
              // eslint-disable-next-line
              href: 'javaScript:;',
              children: (
                <span>
                  <p><img className='bt-img' src="https://pgp1024daren.oss-cn-shanghai.aliyuncs.com/life/static/logo.svg" alt='' />群峰团购</p>
                </span>
              ),
            },
          ],
        },
      },
      // {
      //   name: 'block3',
      //   xs: 24,
      //   md: 6,
      //   className: 'block',
      //   title: {
      //     children: (
      //       <span>
      //         <p>
      //           <br />
      //         </p>
      //       </span>
      //     ),
      //   },
      //   childWrapper: {
      //     children: [
      //       {
      //         name: 'image~ll50vr4izyf',
      //         className: '',
      //         children:
      //           'https://pgp1024daren.oss-cn-shanghai.aliyuncs.com/ice/4/202308/rc-upload-1691573833379-16.jpeg',
      //       },
      //     ],
      //   },
      // },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        <span>©2018 by&nbsp;</span>程序园科技有限公司<span>
          &nbsp;All Rights Reserved
        </span>
      </span>
    ),
  },
};

