import { message } from 'antd'
import axios from 'axios'


const request = axios.create({
  baseURL: '/api',
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
  },
})

// Add a request interceptor
request.interceptors.request.use(
  (config) => {
    console.log('config', config)
    if (/^crm\//.test(config.url)) {
      config.url = config.url.replace('crm/', '')
      config.baseURL = '/crm'
    }
    // Do something before request is sent
    const params = {}
    if (config && config.data) {
      if (config.data.xAppKey) {
        config.headers.set('x-app-key', config.data.xAppKey)
      }
      if (config.data.xAppTimestamp) {
        config.headers.set('x-app-timestamp', config.data.xAppTimestamp)
      }
      if (config.data.xAppSign) {
        config.headers.set('x-app-sign', config.data.xAppSign)
      }
    }

    config.params = {
      ...config.params,
      ...params,
    }
    console.log('config', config)
    return config
  },
  (error) => {
    console.info('error', error)
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
request.interceptors.response.use(
  (response) => {
    // Do something with response data
    const res = response // 响应数据
    console.log('res', res)
    const code = res.code // 响应状态码
    if (code === 203) {
      message.error(res.msg)
    }

    // 全局提示错误
    const is_success = res.is_success // 响应状态码
    if (is_success !== undefined && !is_success) {


      message.error(res.msg)
      return Promise.reject(res)
    }

    if (res.data) {
      // if(res.data?.crate)
      if (res.data.length === undefined) {
        return {
          ...res.data,
          response_msg: res.msg,
        }
      }
      return res.data
    }
    return res
  },
  (error) => {
    console.log('error', error)

    // Do something with response error
    message.error(error.message)
    return Promise.reject(error)
  }
)

export default request
