import React from 'react';
import dayjs from 'dayjs';
import md5 from 'js-md5';
import { Button, Form, Input, Select, message } from 'antd';
import { enquiry, ApiCreateClue } from '../api/user'


const onFinishFailed = (errorInfo) => {
  console.log('Failed:', errorInfo);
};


class UserForm extends React.PureComponent {
  render() {

    const selectOption = [
      { label: '音付', value: 100 },
      { label: '鹿客捷讯', value: 107 },
      { label: '矩阵熊', value: 108 },
      { label: '直播豹', value: 109 },
      { label: '配送狮', value: 110 },
      { label: '音付_招商', value: 111 },
    ]


    const handleChange = (val) => {
      console.log(val);
    };

    const onFinish = (values) => {
      const { project_type_id, ...rest } = values
      const xAppKey = '2024070484784441'
      const xAppTimestamp = dayjs().unix() + ''
      const apiSecret = '0ddff306ad542d11d3ebd30efaa3be50'
      const xAppSign = md5(xAppKey + apiSecret + xAppTimestamp)
      ApiCreateClue({ customer_base_vo: rest, source_vo: { clue_source: 900 }, project_type_id, xAppKey, xAppTimestamp, xAppSign }).then(res => {
        if (res.code !== 200) return message.error(res.msg || '提交失败，请稍后再试！');
        message.success('提交成功！')
      }).finally(() => closeHandle())
    };
    const closeHandle = (errorInfo) => {
      this.props.close(false)
    };
    return (
      <Form
        name="user"
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 20,
        }}
        style={{
          maxWidth: 600,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >

        <Form.Item
          label="姓名"
          name="customer_base_name"
          rules={[
            {
              required: true,
              message: '请输入姓名!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="联系电话"
          name="customer_base_mobile"
          rules={[
            {
              required: true,
              message: '请输入联系电话!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="所属行业"
          name="customer_base_industry"
          rules={[
            {
              required: false,
              message: '请输入所在行业!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="QQ"
          name="customer_base_qq"
          rules={[
            {
              required: false,
              message: '请输入QQ!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="微信"
          name="customer_base_wechat"
          rules={[
            {
              required: false,
              message: '请输入微信!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="使用平台"
          name="project_type_id"
          rules={[
            {
              required: false,
            },
          ]}
          initialValue={100}
        >
          <Select
            placeholder="请选择使用平台"
            // defaultValue='100'
            // onChange={handleChange}
            // mode="multiple"
            allowClear
            options={selectOption}
          >

          </Select>
        </Form.Item>


        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >


          <Button type="primary" size={'large'} htmlType="submit" styles={'width:100px'} >
            提交
          </Button>
        </Form.Item>
      </Form >
    )
  }
}
export default UserForm;