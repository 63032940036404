/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';

import Nav0 from './Nav0';
import Banner0 from './Banner0';
import Feature0 from './Feature0';
import Feature1 from './Feature1';
import Feature2 from './Feature2';
import Teams1 from './Teams1';
import Footer1 from './Footer1';
import {
  Nav00DataSource,
  Banner00DataSource,
  Feature00DataSource,
  Feature10DataSource,
  Feature20DataSource,
  Feature11DataSource,
  Feature21DataSource,
  Feature12DataSource,
  Teams10DataSource,
  Teams11DataSource,
  Footer10DataSource,
} from './data.source';
import './less/antMotionStyle.less';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location = {} } = typeof window !== 'undefined' ? window : {};


export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
    };
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
    /* 如果不是 dva 2.0 请删除 start */
    if (location.port) {
      // 样式 build 时间在 200-300ms 之间;
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }
    /* 如果不是 dva 2.0 请删除 end */
  }

  render() {
    const children = [
      <Nav0
        id="Nav0_0"
        key="Nav0_0"
        dataSource={Nav00DataSource}
        isMobile={this.state.isMobile}
      />,
      <Banner0
        id="Banner0_0"
        key="Banner0_0"
        dataSource={Banner00DataSource}
        isMobile={this.state.isMobile}
      />,
      
      <Feature0
        id="Feature0_0"
        key="Feature0_0"
        dataSource={Feature00DataSource}
        isMobile={this.state.isMobile}
      />,
      <h2 id='functions' style={{textAlign:'center',fontSize: '36px',
      color: 'rgba(0, 0, 0, 0.85)',
      paddingTop: '60px',
      marginBottom: '36px'}}>核心功能</h2>,
      <Feature1
        id="Feature1_0"
        key="Feature1_0"
        dataSource={Feature10DataSource}
        isMobile={this.state.isMobile}
      />,
      <Feature2
        id="Feature2_0"
        key="Feature2_0"
        dataSource={Feature20DataSource}
        isMobile={this.state.isMobile}
      />,
      <Feature1
        id="Feature1_1"
        key="Feature1_1"
        dataSource={Feature11DataSource}
        isMobile={this.state.isMobile}
      />,
      <Feature2
        id="Feature2_1"
        key="Feature2_1"
        dataSource={Feature21DataSource}
        isMobile={this.state.isMobile}
      />,
      <Feature1
        id="Feature1_2"
        key="Feature1_2"
        dataSource={Feature12DataSource}
        isMobile={this.state.isMobile}
      />,
      <Teams1
        id="Teams1_0"
        key="Teams1_0"
        dataSource={Teams10DataSource}
        isMobile={this.state.isMobile}
      />,
      <Teams1
        id="Teams1_1"
        key="Teams1_1"
        dataSource={Teams11DataSource}
        isMobile={this.state.isMobile}
      />,
      <div>
          {this.state.isMobile ?
            (
              <div className='mobile'>
                <dl className='top'>
                  <dt>联系我们</dt>
                  <dt>contact us</dt>
                </dl>

                <img className='bg' src="https://www.pgp1024.com/PGPSystem/resources/asstes/images/conectCode.png?_t=1624264325312" alt="" />
                <dl className='bottom'>
                  <dt>欢迎加 微信 咨询</dt>
                  <dt>微信扫一扫， 即可识别</dt>
                  <dt>（电话：0758-7771024）</dt>
                </dl>
              </div>
            )

            : (
              <div className='pc'>
                <div className='service'>
                <img src="https://www.pgp1024.com/PGPSystem/resources/asstes/images/service.svg?_t=1624264325312" alt="" />
                <span>联系我们</span>
                </div>
                
                <div className='qrcode'>
                  <img src="https://www.pgp1024.com/PGPSystem/resources/asstes/images/conectCode.png?_t=1624264325312" alt="" />
                  <dl>
                    <dt>欢迎加 微信 咨询</dt>
                    <dt>微信扫一扫， 即可识别</dt>
                    <dt>（电话：0758-7771024）</dt>
                  </dl>
                </div>
              </div>

            )
          }
        </div>,
      <Footer1
        id="Footer1_0"
        key="Footer1_0"
        dataSource={Footer10DataSource}
        isMobile={this.state.isMobile}
      />,
    ];
    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >




        {/* 如果不是 dva 2.0 替换成 {children} start */}
        {this.state.show && children}
        {/* 如果不是 dva 2.0 替换成 {children} end */}
        
      </div>
    );
  }
}
