import React from 'react';
import { Button,Modal } from 'antd';
// import { DownOutlined } from '@ant-design/icons';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import { isImg } from './utils';
import UserForm from './UserForm';



class Banner extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
    };
  }

   showModal = () => {
    this.setState({isModalOpen:true});
  };
  
  closeModal = () => {
    this.setState({isModalOpen:false});
  };
  
  render() {
    const { ...currentProps } = this.props;
    const { dataSource } = currentProps;
    delete currentProps.dataSource;
    delete currentProps.isMobile;
    return (
      <div {...currentProps} {...dataSource.wrapper}>
        <QueueAnim
          key="QueueAnim"
          type={['bottom', 'top']}
          delay={200}
          {...dataSource.textWrapper}
        >
          <div key="title" {...dataSource.title}>
            {typeof dataSource.title.children === 'string' &&
            dataSource.title.children.match(isImg) ? (
              <img src={dataSource.title.children} width="100%" alt="img" />
            ) : (
              dataSource.title.children
            )}
          </div>
          <div key="content" {...dataSource.content}>
            {dataSource.content.children}
          </div>
          <Button ghost key="button" {...dataSource.button} onClick={this.showModal}>
            {dataSource.button.children}
          </Button>
        </QueueAnim>
        <TweenOne
          animation={{
            y: '-=20',
            yoyo: true,
            repeat: -1,
            duration: 1000,
          }}
          className="banner0-icon"
          key="icon"
        >
          {/* <DownOutlined /> */}
        </TweenOne>

        {/* 用户表单 */}
        <Modal  open={this.state.isModalOpen} mask title={'联系我们'} wrapClassName={'userModal'}  footer={null} maskClosable onCancel={this.closeModal} width={600} >
          <UserForm close={this.closeModal.bind(this)} />
      </Modal>
      </div>
    );
  }
}
export default Banner;
